@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

$blue-color: #3e17e9;
$error-color: #9a001f;

html,
body,
#root {
  height: 100%;
  font-family: Roboto;
  overflow-x: hidden !important;
}

// Komunikact w centralnej częsci ekranu aplikacji

.center-notification {
  display: flex;
  align-content: space-around;
  justify-content: space-around;
  flex-wrap: wrap;
  height: calc(100vh - 30px);
}

// Instrukcja globalna

.instruction {
  font-size: 15px;
  font-weight: bold;
  text-align: center;

  div {
    display: block;
    font-size: 12px;
    font-weight: normal;
  }

  &.instruction-error {
    color: darken($error-color, 25%);
    background-color: lighten($error-color, 50%);
    border: 1px solid darken($error-color, 25%);
    padding: 25px;
    div {
      font-size: 14px;
    }
  }
}

// render errror

.render-error {
  text-align: center;
  width: 400px;

  h4 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 25px;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    text-align: left;
    margin: 0px auto 20px;
    width: 300px;
  }

  button {
    font-size: 18px;
    font-weight: bold;
    padding: 20px;
    background-color: $blue-color;
    color: #fff;
    min-width: 150px;
    border: none;
  }
}

// Nagrywanie Wideo

.visual-component-instr {
  height: 80px;
  text-align: center;
  display: flex;
  align-content: space-around;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.visual-component {
  max-height: 600px;
  min-height: 408px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-content: space-around;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;

  &--without-inst {
    // margin-top: 15px;
  }

  .footer {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 60px;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    > div {
      width: 100%;
      color: white;
      font-size: 0.8rem;
    }
  }
}

.footer {
  width: 70%;
  display: inline-block;
  text-align: left;
}

@media (max-width: 530px) {
  body {
    overflow-x: scroll;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.transition {
  opacity: 1;
  transition: opacity 3s ease-in;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-restart-process {
  background-image: linear-gradient(
    to right,
    #134e5e 0%,
    #71b280 51%,
    #134e5e 100%
  );
}
.btn-restart-process {
  padding: 10px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  display: block;
  border: 1px solid #134e5e;
  margin-bottom: 10px;
}
.btn-restart-process:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-copy-token {
  color: #aaa;
  display: inline-block;
}

.btn-shape {
  color: #aaa;

  span {
    font-weight: bold;
    color: #71b280;
  }
}

@media (max-width: 600px) {
  .token {
    max-width: 100%;
    margin-top: 60px;
    margin-bottom: 20px;
    flex-direction: column;
  }
}

.video-enter {
  opacity: 0;
}

.video-enter-active {
  opacity: 1;
  transition: opacity 3000ms;
}

.video-exit {
  opacity: 1;
}

.video-exit-active {
  opacity: 0;
  transition: opacity 3000ms;
}

.slick-item {
  margin: 15px;
  height: 125px;
  cursor: pointer;
}

.slick-selected {
  // transform: scale(1.5);
  // transition: all 1s;
  border: 1px solid #ccc;
}

.box-mask {
  position: absolute;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.6);
}

.box-mask-face {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  height: 380px;
  transform: translate(-50%, -50%);
  z-index: 30;
}

.progress-box {
  &__canvas {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    transform: scaleX(-1) translate(50%, -50%);
    z-index: 2;
  }

  &__bar {
    width: 100%;
    color: white;

    .ui.progress {
      height: 4px;
      background: none;
      border: 1px solid #fff;
    }

    &__text {
      width: 100%;

      &--bottom {
        text-align: left;
      }

      &--right {
        float: right;
      }
    }
  }
}

.take-photo-actions {
  position: absolute;
  z-index: 99;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &__btn {
    border: none;
    background: none;
    margin: 30px;
    margin-bottom: 10px;

    color: #155724;
    background-color: #d4edda;
    border-color: #d4edda;
    border-radius: 7px;
    padding: 8px;
    width: 90px;
    min-height: 90px;

    svg {
      font-size: 2rem;
      display: block;
      margin: 0px auto;
      // color: #54a166;
      margin-bottom: 10px;
    }
  }
}

.info-text {
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
  z-index: 20;
  font-size: 1.5rem;
  color: white;
  text-shadow: 2px 2px #000;
}

.record-timer-text {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 5%;
  transform: translate(-50%, -50%);
  z-index: 20;
  font-size: 1.5rem;
  color: white;
  text-shadow: 2px 2px #000;
}

.start-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  font-size: 2.5rem;
  color: white;
  text-shadow: 2px 2px #000;
}

@media screen and (max-width: 450px) {
  .info-text,
  .record-timer-text {
    font-size: 1rem;
  }

  .start-text {
    font-size: 1.5rem;
  }
}

.bar {
  background: #fff;
}

.mask-ol-z {
  z-index: 10;
  opacity: 0.6 !important;
}

.mask {
  position: absolute;
  left: 50%;
  width: 100%;
  max-width: 929px;
  margin: auto;
  height: 100%;
  background-position: center;
  transform: translateX(-50%);

  &--arc {
    &-show {
      &::before {
        content: "";
        z-index: 999;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;

        //border-left-width: 50px;
        border-left: 50px solid #fff;
        opacity: 0.8;
        //border-image: linear-gradient(45deg, rgb(0,143,104), rgb(250,224,66));
        height: 50%;
        width: 35%;
        transform: translate(-50%, -50%) rotateY(92deg);
      }
    }

    &-animated {
      &::before {
        animation: arc-animation 10s linear;
      }
    }
  }
}

.alert-on-top {
  font-size: 1rem;
  width: auto;
  display: inline-block;
  margin-top: 5px;
  border: 1px solid #0dd800;
  border-radius: 7px !important;
  position: relative;
  padding: 0.5rem 1rem;
  color: #155724;
  background-color: #d4edda;
  border-color: #d4edda;
}

@keyframes arc-animation {
  0% {
    transform: translate(-50%, -50%) rotateY(94deg);
  }

  23.75% {
    transform: translate(-50%, -50%) rotateY(180deg);
  }

  51.25% {
    transform: translate(-50%, -50%) rotateY(94deg);
  }

  76.25% {
    transform: translate(-50%, -50%) rotateY(0deg);
  }

  97.5% {
    transform: translate(-50%, -50%) rotateY(92deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateY(92deg);
  }
}

// FROM DDDDDDDD

.MoveAnimation-wrap {
  position: absolute;
  left: 50%;
}
.MoveAnimation {
  color: #00ffd1;
  box-sizing: border-box;
  border: 3px #00ffd1 solid;
  border-radius: 20%;
  position: absolute;
  will-change: box-shadow, opacity;
}
.MoveAnimation::after {
  content: "";
  display: table;
  clear: both;
}
.MoveAnimation.is-animating {
  color: #fff;
  border-color: #fff;
  -webkit-animation: MoveAnimation-highlight 333.3333333333ms linear 2500ms
    alternate 3;
  animation: MoveAnimation-highlight 333.3333333333ms linear 2500ms alternate 3;
}
.MoveAnimation.is-animating::before {
  content: "";
  box-sizing: border-box;
  width: 112%;
  height: 112%;
  border-radius: inherit;
  display: block;
  position: absolute;
  top: -6%;
  left: -6%;
  opacity: 0;
  box-shadow: -25px 0 10px -6px rgba(0, 221, 221, 0.4),
    inset 12px 0 10px -6px rgba(0, 221, 221, 0.4);
  will-change: opacity;
  -webkit-animation: MoveAnimation-shadow 333.3333333333ms linear 2500ms
    alternate 3;
  animation: MoveAnimation-shadow 333.3333333333ms linear 2500ms alternate 3;
}
.MoveAnimation-line {
  color: rgba(255, 255, 255, 0.8);
  width: 0%;
  height: 100%;
  border-left: 2px solid #fff;
  border-radius: 50%;
  float: left;
  position: relative;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  box-shadow: -6px 0 6px -3px, inset 6px 0 6px -3px;
  will-change: width, border, box-shadow;
  animation: line-center-left 333.3333333333ms linear 2500ms alternate-reverse 3,
    line-center-left 2300ms linear 3500ms forwards 1,
    line-left-center 2300ms linear 5800ms forwards 1,
    line-center-right 2300ms linear 8100ms forwards 1,
    line-right-center 2100ms linear 10400ms forwards 1;
}
.MoveAnimation-arrow {
  width: 40px;
  height: 40px;
  float: left;
  position: absolute;
  top: 40%;
  left: calc(0% + 50px);
  transform: translate3d(0, 0, 0);
  background-color: #fff;
  opacity: 0;
  will-change: left, opacity;
  -webkit-animation: MoveAnimation-arrow 333.3333333333ms linear 2500ms
    alternate 3;
  animation: MoveAnimation-arrow 333.3333333333ms linear 2500ms alternate 3;
}
.MoveAnimation-arrow::before {
  content: "";
  width: 0;
  height: 0;
  border: 50px solid #fff;
  border-left: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
  display: inline-block;
  float: left;
  position: relative;
  bottom: 30px;
  left: -50px;
}
@-webkit-keyframes line-center-left {
  from {
    width: 0%;
    border-right: none;
    border-left: 2px solid #fff;
    -webkit-clip-path: polygon(-10% 0%, -10% 100%, 50% 100%, 50% 0%);
  }
  to {
    width: 100%;
    -webkit-clip-path: polygon(-10% 0%, -10% 100%, 50% 100%, 50% 0%);
  }
}
@keyframes line-center-left {
  from {
    width: 0%;
    border-right: none;
    border-left: 2px solid #fff;
    -webkit-clip-path: polygon(-10% 0%, -10% 100%, 50% 100%, 50% 0%);
  }
  to {
    width: 100%;
    -webkit-clip-path: polygon(-10% 0%, -10% 100%, 50% 100%, 50% 0%);
  }
}
@-webkit-keyframes line-left-center {
  from {
    width: 100%;
    border-right: none;
    border-left: 2px solid #fff;
    -webkit-clip-path: polygon(-10% 0%, -10% 100%, 50% 100%, 50% 0%);
  }
  to {
    width: 0%;
    -webkit-clip-path: polygon(-10% 0%, -10% 100%, 50% 100%, 50% 0%);
  }
}
@keyframes line-left-center {
  from {
    width: 100%;
    border-right: none;
    border-left: 2px solid #fff;
    -webkit-clip-path: polygon(-10% 0%, -10% 100%, 50% 100%, 50% 0%);
  }
  to {
    width: 0%;
    -webkit-clip-path: polygon(-10% 0%, -10% 100%, 50% 100%, 50% 0%);
  }
}
@-webkit-keyframes line-center-right {
  from {
    width: 0%;
    border-right: 2px solid #fff;
    border-left: none;
    -webkit-clip-path: polygon(50% 0%, 50% 100%, 110% 100%, 110% 0%);
    box-shadow: 6px 0 6px -3px, inset -6px 0 6px -3px;
  }
  to {
    width: 100%;
    border-right: 2px solid #fff;
    border-left: none;
    -webkit-clip-path: polygon(50% 0%, 50% 100%, 110% 100%, 110% 0%);
    box-shadow: 6px 0 6px -3px, inset -6px 0 6px -3px;
  }
}
@keyframes line-center-right {
  from {
    width: 0%;
    border-right: 2px solid #fff;
    border-left: none;
    -webkit-clip-path: polygon(50% 0%, 50% 100%, 110% 100%, 110% 0%);
    box-shadow: 6px 0 6px -3px, inset -6px 0 6px -3px;
  }
  to {
    width: 100%;
    border-right: 2px solid #fff;
    border-left: none;
    -webkit-clip-path: polygon(50% 0%, 50% 100%, 110% 100%, 110% 0%);
    box-shadow: 6px 0 6px -3px, inset -6px 0 6px -3px;
  }
}
@-webkit-keyframes line-right-center {
  from {
    width: 100%;
    border-right: 2px solid #fff;
    border-left: none;
    -webkit-clip-path: polygon(50% 0%, 50% 100%, 110% 100%, 110% 0%);
    box-shadow: 6px 0 6px -3px, inset -6px 0 6px -3px;
  }
  to {
    width: 0%;
    border-right: 2px solid #fff;
    border-left: none;
    -webkit-clip-path: polygon(50% 0%, 50% 100%, 110% 100%, 110% 0%);
    box-shadow: 6px 0 6px -3px, inset -6px 0 6px -3px;
  }
}
@keyframes line-right-center {
  from {
    width: 100%;
    border-right: 2px solid #fff;
    border-left: none;
    -webkit-clip-path: polygon(50% 0%, 50% 100%, 110% 100%, 110% 0%);
    box-shadow: 6px 0 6px -3px, inset -6px 0 6px -3px;
  }
  to {
    width: 0%;
    border-right: 2px solid #fff;
    border-left: none;
    -webkit-clip-path: polygon(50% 0%, 50% 100%, 110% 100%, 110% 0%);
    box-shadow: 6px 0 6px -3px, inset -6px 0 6px -3px;
  }
}
@-webkit-keyframes MoveAnimation-highlight {
  from {
    box-shadow: -45px 0 10px -30px #00ffd1;
  }
  to {
    box-shadow: -45px 0 10px -30px transparent;
  }
}
@keyframes MoveAnimation-highlight {
  from {
    box-shadow: -45px 0 10px -30px #00ffd1;
  }
  to {
    box-shadow: -45px 0 10px -30px transparent;
  }
}
@-webkit-keyframes MoveAnimation-shadow {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@keyframes MoveAnimation-shadow {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@-webkit-keyframes MoveAnimation-arrow {
  from {
    left: calc(0% + 50px);
    opacity: 0.7;
  }
  to {
    left: calc(50% + 50px);
    opacity: 0.7;
  }
}
@keyframes MoveAnimation-arrow {
  from {
    left: calc(0% + 50px);
    opacity: 0.7;
  }
  to {
    left: calc(50% + 50px);
    opacity: 0.7;
  }
}

#Ticks {
  width: 0;
  height: 0;
  opacity: 0;
}
#Tick-line {
  stroke-width: 1px;
  stroke: #000;
}
#Tick-hilite-line {
  stroke-width: 2px;
  stroke: #2da698;
}
.TickScale {
  width: 90%;
  height: 75px;
  max-width: 300px;
  padding: 0;
  display: flex;
  position: relative;
  align-self: center;
}
.TickScale-left,
.TickScale-right {
  display: flex;
  position: relative;
  flex: 1 1 50%;
}
.TickScale-left {
  transform: scaleX(-1);
}
.TickScale-center {
  color: #000;
  width: 2px;
  height: 70%;
  position: absolute;
  top: 15%;
  left: calc(50% - 1px);
  flex: 0 0 auto;
  background-color: #000;
}
.TickScale-indicator {
  color: #00ffd1;
  height: 80%;
  top: 10%;
  transform: translate3d(0, 0, 0);
  background-color: #00ffd1;
  opacity: 0;
  box-shadow: 0 0 3px 0;
  will-change: left, opacity;
  -webkit-animation: show-indicator 50ms 2500ms linear 1 forwards,
    go-left 2300ms 3500ms linear 1 forwards,
    go-left-center 2300ms 5800ms linear 1 forwards,
    go-right 2300ms 8100ms linear 1 forwards,
    go-right-center 2100ms 10400ms linear 1 forwards;
  animation: show-indicator 50ms 2500ms linear 1 forwards,
    go-left 2300ms 3500ms linear 1 forwards,
    go-left-center 2300ms 5800ms linear 1 forwards,
    go-right 2300ms 8100ms linear 1 forwards,
    go-right-center 2100ms 10400ms linear 1 forwards;
}
.TickScale-static {
  will-change: left, opacity;
  -webkit-animation: glow 50ms 3500ms linear 1 forwards;
  animation: glow 50ms 3500ms linear 1 forwards;
}
[dir="rtl"] .TickScale-left {
  order: 2;
}
@-webkit-keyframes show-indicator {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes show-indicator {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes go-left {
  to {
    left: calc(0% + 2px);
  }
}
@keyframes go-left {
  to {
    left: calc(0% + 2px);
  }
}
@-webkit-keyframes go-left-center {
  from {
    left: calc(0% + 2px);
  }
  to {
    left: calc(50% - 1px);
  }
}
@keyframes go-left-center {
  from {
    left: calc(0% + 2px);
  }
  to {
    left: calc(50% - 1px);
  }
}
@-webkit-keyframes go-right {
  to {
    left: calc(100% - 4px);
  }
}
@keyframes go-right {
  to {
    left: calc(100% - 4px);
  }
}
@-webkit-keyframes go-right-center {
  from {
    left: calc(100% - 4px);
  }
  to {
    left: calc(50% - 1px);
  }
}
@keyframes go-right-center {
  from {
    left: calc(100% - 4px);
  }
  to {
    left: calc(50% - 1px);
  }
}
@-webkit-keyframes glow {
  from {
    color: #000;
    background-color: #000;
  }
  to {
    color: #2da698;
    background-color: #2da698;
  }
}
@keyframes glow {
  from {
    color: #000;
    background-color: #000;
  }
  to {
    color: #2da698;
    background-color: #2da698;
  }
}
.Ticks {
  width: 100%;
  height: 100%;
}
.Ticks-hilite {
  position: absolute;
  top: 0;
  left: 0;
}
.Ticks-animate {
  transform: translateX(-100%);
}
.Ticks-animate-left {
  -webkit-animation: go-out 2300ms 3500ms linear 1 forwards;
  animation: go-out 2300ms 3500ms linear 1 forwards;
}
.Ticks-animate-right {
  -webkit-animation: go-out 2300ms 8100ms linear 1 forwards;
  animation: go-out 2300ms 8100ms linear 1 forwards;
}

// App footer

.app-footer {
  background-color: rgb(128, 130, 255);
  
  bottom: 0px;
  color: #fff;
  margin: 0px;
  padding: 5px;
  font-size: 11px;
  vertical-align: middle;

  button {
    background: none;
    border: none;
    color: #fff;
  }

  > .copy-log {
    float: right;
  }
}

///media do modal

@media (max-width: 530px) {
 .btn-copy-token {
    font-size: 0.8rem;
 }

 .btn-restart-process {
   margin: 0;
   font-size: 0.8rem;
   padding: 10px 25px;
 }

 .take-photo-actions__btn {
  font-size: 0.8rem;
  width: 115px;
  margin: 20px;

  svg {
    font-size: 2.5rem;
  }
 }

 .alert-on-top {
  font-size: 1.1rem;
 }
 
 .footer{
  width: auto;
 }
}






.Virtual-Mirror-App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Virtual-Mirror-Content {
  flex: 1;
  max-height: calc(100vh - 30px);
}

footer {
  min-height: 30px;
  max-height: 30px;
  vertical-align: middle;
}